<template>
    <div id="CommonAlert1">
        <div class="modal fade " id="commonAlert1" tabindex="-1" role="dialog" aria-labelledby="notice1Label" aria-hidden="true">
            <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body text-center" >
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="h3 mt-3 mb-4 fw-bolder">{{title}}</div>
                        <!--eslint-enable-->
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="h5 mt-2 ">{{content}}</div>
                        <!--eslint-enable-->
                        <div class="mt-4 mb-2">
                            <button type="button" class="btn btn-success rounded-pill w-75" data-bs-dismiss="modal" @click="onFunc">{{btnnameup}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CommonAlert1',    
    props: {
        title: String,
        content: String,
        btnnameup: String,
        onfunc: Function,
    }, 
    methods: {
        onShow(){
            $('#commonAlert1').modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonAlert1').modal("hide");
        },
        onFunc(){
            if(this.onfunc != null && this.onfunc != undefined){
                $('#commonAlert1').on('hidden.bs.modal', () => {
                    $('#commonAlert1').off('hidden.bs.modal');
                    this.onfunc();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#commonAlert1').modal("hide");
        }
    },
    created() {  
    },
    mounted(){  
    }
};
</script>