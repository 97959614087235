<template>
<div id="SpecialUpgradeVip">
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfuncup="onFuncUp" :onfunc="onFuncUp"/>
  <Header/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          特價升級VIP會員
        </a>
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
    <!-- End header -->
    <div class="nicolespacetop"></div>
    <!-- End header -->
 
    <div class="">
      <div class="" style="position: absolute;">
        <img class="w-100 d-md-none" src="assets/img/img061_phone.png">
        <img class="w-100 d-none d-md-block" src="assets/img/img061_pad.png">
        <div class="w-90 d-flex align-content-center justify-content-between cardname ">
          <div class="d-flex pt-2">
            <h5 class="text-white text-bolder text-card-name mb-0 me-2"> {{ name }}</h5>
            <span class="badge badge-danger nicolebadge rounded-pill text-xs"> {{ userLevelName }}</span>
          </div>
          <!-- 使用者頭像 -->

          <img v-if="config && config.old_images && config.old_images=='Y'" class=" avatar rounded-circle" v-real-img="'data:image/jpeg;base64,'+icon" src="assets/img/camera-no.png">  
          <img v-if="config && config.old_images && config.old_images=='N'" class=" avatar rounded-circle" v-real-img="''+icon" src="assets/img/camera-no.png">
        </div> 
        <!-- 就你在搞 -->
          <div class=" cardpromote w-95  mt-md-3">
            <div class="card">
              <img class="card-img-top card-img-nicole" v-real-img="banner" src="assets/img/noback.png">

              <!-- 波浪動畫 -->
              <div class="position-relative" style="height: 50px;overflow: hidden;margin-top: -50px;z-index:2;position: relative;">
                <div class="position-absolute w-100 top-0" style="z-index: 1;">
                  <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                      <path id="card-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g class="moving-waves">
                      <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                      <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                      <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                      <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                      <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                      <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                    </g>
                  </svg>
                </div>
              </div>


              <div class="card-body pt-3">
                <div class="d-flex mb-1">
                  <!-- eslint-disable vue/no-v-html -->
                  <img src="assets/img/ic_vip_crown.png" class="avatar avatar-sm" v-html="'&nbsp;'">
                  <!--eslint-enable--> 
                  <h5 class="text-sm text-gray mt-1 ">開通會員，解鎖更多課程！</h5>
                </div>
                <h5 class="fw-bolder text-n">{{ productName }}</h5>
                <!-- eslint-disable vue/no-v-html -->
                <h6 class="text-smm text-gray" v-html="productDescription"></h6>
                <!--eslint-enable-->
                <div>
                  
                  <div class="d-flex mt-3">
                      <!-- eslint-disable vue/no-v-html -->
                      <h5 class="fw-bolder mb-0" v-html="'特價:&nbsp;'"></h5>
                      <!--eslint-enable--> 
                      <h5 class="text-danger fw-boldest  mb-0">＄{{productPrice}} </h5>   
                      <!-- eslint-disable vue/no-v-html -->
                      <h4 class="text-info text-simbold text-xsm  mb-0 mt-1" v-html="'&nbsp;&nbsp;'"> </h4>
                      <h4 class="text-info text-simbold text-xsm  mb-0 mt-1" v-html="productPriceDescription"> </h4>
                      <!--eslint-enable--> 
                  </div>

                  <div class="mt-2 text-center">
                    <!-- <button type="button" class="btn btn-warning py-2 px-4 rounded-pill w-100 text-smm"  data-bs-toggle="modal" data-bs-target="#buy" :class="(ulid != 'level4') ? '' : 'disabled'">{{ productButtonText }}</button> -->
                    <button type="button" class="btn btn-warning py-2 px-4 rounded-pill w-100 text-smm" @click="showModal('#buy')" :class="(ulid != 'level4') ? '' : 'disabled'">{{ productButtonText }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 ms-2 mb-3 mt-4">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="autoRenewalStatement"></span>
              <!--eslint-enable-->
            </div>
            <div class="text-center mt-5">
              <h5 class="text-brown text-card-title text-simbold">~ 開通 VIP 會員享有 ~</h5>
              <div class="d-flex mt-4">
                <div class="col-4">
                  <img src="assets/img/ic_vip_level.png" class="img-fluid avatar avatar-l">
                  <h5 class="text-brown text-card-small text-simbold mt-1">自訂課程</h5>
                </div>             
                <div class="col-4">
                  <img src="assets/img/ic_vip_reading.png" class="img-fluid avatar avatar-l">
                  <h5 class="text-brown text-card-small text-simbold mt-1">課程學習無上限</h5>
                </div>             
                <div class="col-4">
                  <img src="assets/img/ic_vip_audio.png" class="img-fluid avatar avatar-l">
                  <h5 class="text-brown text-card-small text-simbold mt-1">影音專區</h5>
                </div>    
              </div>             
              <div class="d-flex mt-4">
                <div class="col-4">
                  <img src="assets/img/ic_vip_save.png" class="img-fluid avatar avatar-l">
                  <h5 class="text-brown text-card-small text-simbold mt-1">收藏功能</h5>
                </div>             
                <div class="col-4">
                  <img src="assets/img/ic_vip_download.png" class="img-fluid avatar avatar-l">
                  <h5 class="text-brown text-card-small text-simbold mt-1">下載音檔</h5>
                </div>             
                <div class="col-4">
                </div>    
              </div>    
            </div>  
            <div class="nicolespace"></div>
            <div class="nicolespace"></div>
          </div>
        </div>
      </div>

    <!-- footer -->
      <nav class="navbar  navbar-light bg-white z-index-3  fixed-bottom py-1">
          <div class="col-12 px-0">
          <div class="d-flex">
              <div class="col-3 text-center">
                <img src="assets/img/ic_tab_home_unselected.png" class="avatar avatar-nicole-sm2"> 
                  <h6 class="mb-0 text-muted small text-simbold">首頁</h6>
              </div>       
              <div class="col-3 text-center">
                <img src="assets/img/ic_tab_dailyessay_unselected.png" class="avatar avatar-nicole-sm2"> 
                  <h6 class="mb-0 text-muted small text-simbold">每日學習</h6>
              </div>        
              <div class="col-3 text-center">
                <img src="assets/img/ic_tab_course_unselected.png" class="avatar avatar-nicole-sm2"> 
                  <h6 class="mb-0 text-muted small text-simbold">深度學習</h6>
              </div>       
              <div class="col-3 text-center">
                <img src="assets/img/ic_tab_me.png" class="avatar avatar-nicole-sm2"> 
                  <h6 class="mb-0 text-muted small text-simbold text-success">我的</h6>
              </div>
          </div>
          </div>
      </nav>
    <!-- End footer -->
    <!-- Modal -->
      <div class="modal fade " id="buy" tabindex="-1" role="dialog" aria-labelledby="buyLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content ">
            <div class="modal-body m-2">
              <h4 class="text-center fw-bold mb-3 ">正在透過APP Store 購買</h4>
              <h6 class="text-muted text-simbold">方案 ：{{ productName }}</h6>
              <h6 class="text-muted text-simbold">權益 ：{{ productRightsDescription }}</h6>
              <h6 class="text-muted text-simbold">價格 ：${{ productPrice }}/{{ productProductUnit }}</h6>
              <div class="card bg-light nicole-noshadow my-2 ps-1 pe-3 overflow-auto" style="height: 28vh;">
                <!-- eslint-disable vue/no-v-html -->
                <div class="mt-1 " v-html="autoRenewalStatement"> </div>
                <!--eslint-enable-->
              </div>
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" value="" @click="onClickCheckBox()">
                <label class="custom-control-label text-sm text-muted text-simbold" for="customCheck1">我已充分了解並同意用戶協議，隱私權政策和自動續費服務協議。</label>
              </div>
              <div class="text-danger text-sm">＊ 本產品為數位內容服服務，正式開通後將無法退款，敬請見諒。</div>
            </div>
            <div class="border-top d-flex align-content-center justify-content-between">
              <div class="w-50 text-center border-end py-3" data-bs-dismiss="modal">
                <h5 class="text-success text-simbold mb-0">取消</h5>
              </div>
              <div class="w-50 text-center py-3">
                <div v-if="isCheckBox">
                  <h5 class="text-success text-simbold mb-0 " @click="onClickPurchase()">繼續購買</h5>
                </div>
                <div v-else>
                  <h5 class=" text-simbold mb-0 ">繼續購買</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- End footer -->    

  </body>
  <Footer/>
</div>
</template>

<script>
import swal from "sweetalert2";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CommonAlert1 from '@/components/CommonAlert1.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 


export default {
    name: 'SpecialUpgradeVip',
    components: {
        Header,
        Footer,
        CommonAlert1,
    },
    data () {
      return { 
        // api setting
        setDebugMode:true ,
        apiDebug: false,
        apiName: "",
        perPage: 1,  //筆數
        pageMode: false,
        perPageOptions: [5, 10, 25, 50 , 75 , 100],
        filterData: {},
        form:{
          seq: 10,
          switch: true,
        },


        // data
        name: "",
        userLevelName: "",
        ulid: "",
        icon: "",
        tempIcon: "",
        autoRenewalStatement: "", // 續費服務聲明
        banner: "", // 圖片

			 config:"",
        productList: [], 
        productName: "", // 名稱
        productRightsDescription: "", // 權益說明
        productDescription: "", // 說明
        productPrice: "", // 價錢
        productPriceDescription: "", // 價錢備註
        productButtonText: "", // 按鈕文字
        productStartTime: "", // 開始時間
        productEndTime: "", // 結束時間
        productProductUnit: "", // 價錢單位

        // status
        isCheckBox: false,


        // alert
        title: "",
        content: "",
        btnNameUp: "",
        onFuncUp: Function,


      }
    },
    methods: {
      openLogin(){
        console.log("openLogin");
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"ContactService", type:""}), this.openLoginCallback);
      },
      openLoginCallback(){

      },
      setOnBack(){
          this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push(this.$route.params.back);
        } else if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          this.$router.push(this.$route.query.back);
        } else {
          this.$router.push({
            name: "Mine"
          });
        }
      },
      getPageSpecialUpgradeVip(){

        this.filterData["uid"] = this.$GlobalData.uid;

        this.apiName="page_special_upgrade_vip";

        if (this.$JsBridge.isAndroid()) {
          this.filterData["device"] = "android";
        } else if (this.$JsBridge.isiOS()) {
          this.filterData["device"] = "IOS";
        } else {
          this.filterData["device"] = "broser";
        }

console.log("getPageSpecialUpgradeVip");
        oAC.getAllRecord(oAC.getApiParam(this), this.filterData).then(res => {
          if (res != undefined && res.user != null && res.user.length > 0) {
            if (res.user[0].name != "") {
              this.name = res.user[0].name;
            } else {
              this.name = "Student";
            }
            if (res.user[0].user_level_name != "") {
              this.userLevelName = res.user[0].user_level_name;
            }
            if(res.user[0].image_url != ""){
			
			if(config && config.old_images && config.old_images=="Y") 
			{  		   
              this.tempIcon = res.user[0].image_url;
			} 
			else 
			{  
              this.tempIcon = res.user[0].image;
			} 
			
              this.getUserIcon();
            }
            if (res.user[0].ulid != "") {
              this.ulid = res.user[0].ulid;
            }
            
          }
          if(res != null && res.auto_renewal_statement != null && res.auto_renewal_statement.length > 0){
            this.autoRenewalStatement = res.auto_renewal_statement[0].description;
          }

          if(res != null && res.product != null && res.product.length > 0){
            this.productList = res.product;
            this.productName = res.product[0].name;
            this.productDescription = res.product[0].description;
            this.productRightsDescription = res.product[0].rights_description;
            this.productPrice = res.product[0].price;
            this.productPriceDescription = res.product[0].price_description;
            this.productButtonText = res.product[0].button_text;
            this.productProductUnit = res.product[0].product_unit.substr(0,1);

			if(config && config.old_images && config.old_images=="Y") 
			{  		
				this.apiName = "image";
				oAC.getAllRecord(oAC.getApiParam(this), {url: res.product[0].image_url, key: 0}).then(
				imgRes => {
					if(imgRes.image != ""){
					  this.banner = 'data:image/jpeg;base64, '+imgRes.image;
					}
				  }
				)
				.catch((err)=> {
					oltConsole.log(err);
				});    
			} 
			else 
			{  
					  this.banner = res.product[0].image_url;
			} 
			
          } else {
            this.title = "提示";
            this.content = "該活動已結束！";
            this.btnNameUp = "確定";
            this.onFuncUp = this.openHome;
            this.$refs.commonAlert1.onShow();
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      getUserIcon(){
        if(this.tempIcon != ""){
          this.apiName = "page_mine_icon";
          oAC.getAllRecord(oAC.getApiParam(this), {url:this.tempIcon}).then(res => {
              if(res && res.image && res.image != ""){                        
                  this.icon = res.image;
              } else {
                  this.icon = "";
              }
            }
          ).catch((err)=> {
              this.icon = "";
              oltConsole.log(err);
          });
        }
      },
      onClickPurchase(){
        var json = {
          uid: this.$GlobalData.uid+"", 
          ptid: this.productList[0].ptid+"", 
          price: this.productList[0].price+"", 
          android_productid:this.productList[0].product_number_android+"", 
          ios_productid:this.productList[0].product_number_ios+"" 
        };
        this.$JsBridge.callHandler('iabSubscribeVue',JSON.stringify( json ), this.subscribeCallback);
      },
      subscribeCallback(jsonStr){
        if(jsonStr != null && jsonStr != undefined && jsonStr != ""){
          if (jsonStr == "Y") {
            this.getUserData();
          } 
          else {
            var decodeJsonStr = decodeURIComponent(atob(jsonStr));
            var json = JSON.parse(decodeJsonStr);
            $("#buy").modal('hide');

            //購買失敗跳視窗
            if ( jsonStr.indexOf("already") >= 0){
              this.title = "重複訂閱";
              this.content = "此內購帳號已訂閱，無法重複使用。如欲變更方案，請至下方連結做變更。";
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            } 
            // 找不到購買項目, 你已經擁有這項商品 :
            // Google 會跳出「Fatal error during the API action.」；Apple 會跳出「」
            else if ( jsonStr.indexOf("error") >= 0){
              this.title = "錯誤";
              this.content = jsonStr;
              this.btnNameUp = "確定";
              this.$refs.commonAlert1.onShow();
            }
            else {
              this.title = "";
              this.content = "已取消!";
              this.btnNameUp = "確定";

              this.$refs.commonAlert1.onShow();
            }
          }
        }
      },
      onClickCheckBox(){
        if (this.isCheckBox) {
          this.isCheckBox = false;
        } else {
          this.isCheckBox = true;
        }
      },
      openHome(){
        this.$router.push({
          name: "Home"
        });
      },
      getUserData(){
        this.apiName="member_get_user_data";
        oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid} ).then(res => {
          if (res != undefined && res.record != null && res.record.length > 0 && res.rt != null && res.rt.user != null && res.rt.user.length > 0 ) {
            this.setUserData(res);
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      setUserData(res){
        var json = {
          record: res.record,
          rt: res.rt,
          token: this.$GlobalData.token
        };
        this.$GlobalData.user = res.rt.user;
        this.$JsBridge.callHandler('setUserData',JSON.stringify( json ), this.setUserDataCallback);
      },
      setUserDataCallback(){
        $("#buy").modal('hide');
        this.getPageSpecialUpgradeVip();
        this.$forceUpdate();
      },
      showModal(target){
        $(target).modal("show");
      }




    },
    created(){
      if (this.$route.query.uid != undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if (this.$GlobalData.uid == undefined || this.$GlobalData.uid == "") {
        this.openLogin();
        return false;
      }
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
      this.getPageSpecialUpgradeVip();
    },
    updated(){ 
      
    }
};
</script>
<style type="text/css">
  .input_color{
    border: transparent !important;
  }

  /*隱藏文字區塊的縮放三角形*/
  .input_textarea{
    resize: none;
    min-height: 32vw !important;
  }


  /*隱藏輸入數字的上下箭頭*/
  /* Chrome, Safari, Edge, Opera */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  input:focus{outline:none}

  .cardpromote{
    top: 50% !important;
  }
</style>